import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { SET_THEME } from 'store/actions';
// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

// auth
import UserProvider from 'contexts/UserContext';
import StatusProvider from 'contexts/StatusContext';
import { SnackbarProvider } from 'notistack';

import { Amplify } from 'aws-amplify';
import {cognitoUserPoolConfig} from './aws-exports';

import { Hub } from 'aws-amplify/utils';
import { fetchAuthSession} from 'aws-amplify/auth';
import { LOGIN } from 'store/actions';
import { useNavigate } from 'react-router';

// ==============================|| APP ||============================== //

Amplify.configure({Auth: {Cognito: cognitoUserPoolConfig}});


const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customization = useSelector((state) => state.customization);

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      dispatch({ type: SET_THEME, theme: storedTheme });
    }
  }, [dispatch]);

  const getUser = async () => {
    /*
    Id:          user.Id,
		Username:    user.Username,
		DisplayName: user.DisplayName,
		Role:        user.Role,
		Status:      user.Status,   
    */
   try {
    const session = await fetchAuthSession();
    const payload = session.tokens?.idToken?.payload;
    if (!payload) {
       throw  Error('notAuthenticated');
    }
    const user = {
      id: payload['custom:id'],
      username: payload['nickname'],
      displayName: payload['nickname'],
      role: Number(payload['custom:role']),
      status: true,
    }
    localStorage.setItem('user', JSON.stringify(user));
    dispatch({ type: LOGIN, payload: user });
    navigate('/panel');
  } catch(error) {
    console.log(error);
    localStorage.removeItem('user');
  //  dispatch({ type: LOGIN, payload: null });
  }
  }

  useEffect(() => {
   // localStorage.setItem('theme', customization.theme);
   const hubListener = Hub.listen('auth', async (data) => {
    const { payload } = data;
    switch (payload.event) {
      case 'signedIn':
      case 'signInWithRedirect':
        await getUser();
        break;
      case 'signedOut':
       // this.appService.setUser(null);
        break;
      default:
        console.log('Other event: ', payload.event);
    }
  });
  return () => {
    hubListener();
  };
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <SnackbarProvider autoHideDuration={5000} maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <UserProvider>
              <StatusProvider>
                <Routes />
              </StatusProvider>
            </UserProvider>
          </SnackbarProvider>
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
