
// https://docs.amplify.aws/gen1/angular/build-a-backend/auth/set-up-auth/
import { CognitoUserPoolConfig } from "@aws-amplify/core/dist/esm/singleton/Auth/types";

// https://docs.amplify.aws/gen1/angular/build-a-backend/auth/set-up-auth/

export const cognitoUserPoolConfig: CognitoUserPoolConfig = {
    userPoolId: 'us-east-2_mZ0XDTkVg',
    signUpVerificationMethod: 'code',
    userPoolClientId: '2i7rt7opat90l3vfqfc31jn8fi',
    loginWith: {
        oauth: {
            domain: 'auth.wahooai.com',
            scopes: [
                'email',
                'openid',
                'profile'
            ],
            redirectSignIn: ['http://localhost:3001', 'https://my.wahooai.com', 'https://one-dev.wahooai.com'],
            redirectSignOut: ['http://localhost:3001',  'https://my.wahooai.com',  'https://one-dev.wahooai.com'],
            responseType: 'code',
            providers: ['Google']
        },
        username: true,
        email: true,
       
        
    },
  
    
};