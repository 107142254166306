import { showError } from './common';
import axios from 'axios';
import { store } from 'store/index';
import { LOGIN } from 'store/actions';
import  appConfig from 'config';
import { fetchAuthSession } from 'aws-amplify/auth';

export const API = axios.create({
  baseURL: process.env.REACT_APP_SERVER ? process.env.REACT_APP_SERVER : '/'
});

// Add a request interceptor to include the Authorization token
API.interceptors.request.use(
  async (config) => {
    try {
      const session = await fetchAuthSession();
      const idToken = session.tokens?.idToken?.toString();
      if (idToken) {
        config.headers['Authorization'] = `Bearer ${idToken}`;
      } 
    } catch (error) {
      // Handle errors from fetchAuthSession
      console.error('Error fetching auth session:', error);
      // You can also log the user out or trigger other actions if needed
      // localStorage.removeItem('user');
      // store.dispatch({ type: LOGIN, payload: null });
      // window.location.href = config.basename + 'login';
     // return Promise.reject(error);  
    }

    return config;
     
  },
  (error) => {
    return Promise.reject(error);
  }
);


API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('user');
      store.dispatch({ type: LOGIN, payload: null });
      window.location.href = appConfig.basename + 'login';
    }

    if (error.response?.data?.message) {
      error.message = error.response.data.message;
    }

    showError(error);
  }
);
